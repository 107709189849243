<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            indeterminate
            size="50"
            color="primary"
        ></v-progress-circular>
      </v-overlay>

      <v-row>

        <v-col cols="12">
          <v-card class="rounded-lg shadow"
                  :loading="overlay"
                  :disabled="overlay">

            <v-card-title>
              <v-spacer/>

              <v-btn @click="exportLogs"
                     :disabled="!logs.length"
                     depressed>
                <v-icon left>mdi-download-outline</v-icon>
                Exporter
              </v-btn>

              <v-btn @click="deleteAll"
                     :disabled="!logs.length"
                     depressed
                     class="mx-2">
                <v-icon left>mdi-delete-outline</v-icon>
                Vider la table
              </v-btn>


              <v-tooltip top color="primary">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-bind="attrs" v-on="on" icon @click="refresh">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </template>
                <span>Actualiser</span>
              </v-tooltip>

            </v-card-title>

            <v-card-text v-if="loading && logs.length === 0">
              <v-skeleton-loader type="table"/>
            </v-card-text>

            <v-card-text v-if="!loading && logs.length ===0"
                         class="text-center">
              <NoResult/>
            </v-card-text>

            <div v-if="!loading && logs.length>0">
              <v-data-table
                  :headers="headers"
                  :items="logs"
                  :items-per-page="5"
                  :search="search"
              >
              </v-data-table>
            </div>

          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";
import NoResult from "../../components/NoResult";


export default {
  components: {NoResult},
  data() {
    return {
      headers: [
        {text: 'Source', value: 'source'},
        {text: 'Ligne', value: 'line'},
        {text: 'Message', value: 'message'},
        {text: 'Info', value: 'info'},
        {text: 'File', value: 'file'},
        {text: 'Request', value: 'request'},
        {text: 'Date', value: 'created_at'},
      ],
      loading: false,
      overlay: false,
      logs: [],
      search: null
    }
  },
  methods: {
    getLogs() {
      this.logs = []
      this.loading = true
      this.$Progress.start()
      HTTP.get('/logs').then((res) => {
        this.loading = false
        this.$Progress.finish()
        this.logs = res.data.data
      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },

    exportLogs() {
      let baseUrl = process.env.VUE_APP_FILE_URL
      let token = this.$store.state.user.token
      let userId = this.$store.state.user.id
      let DownloadURL = baseUrl + '/export/excel/logs?token=' + token + '&id=' + userId;
      window.open(DownloadURL, '_blanc')
    },
    handleDelete() {
      this.$is_confirm = false
      this.overlay = true
      this.$Progress.start()
      HTTP.delete('/logs/delete-all').then(() => {
        this.$successMessage = 'Cettes erreurs a été supprimée avec succès'
        this.overlay = false
        this.$Progress.finish()
        this.getLogs()
      }).catch(err => {
        this.overlay = false
        this.$Progress.fail()
        console.log(err)
      })
    },
    deleteAll() {
      this.$confirm_dialog_body = 'Êtes-vous sûr de vouloir VIDER ce tableau'
      this.$confirm_dialog = true
    },
    refresh() {
      this.getLogs()
    },
  },
  created() {
    this.getLogs()
  },
  watch: {
    '$is_confirm': function (el) {
      if (el) {
        this.handleDelete()
      }
    },
  },
}
</script>

<style scoped>

</style>